import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { HubspotUserCreateInitialStateType, HubspotUserCreateResponse, HubspotUserCreateType } from "../../../models/hubspot/hubspot-user-create.model";
import HubspotUserCreateService from "../../../services/hubspot/hubspot-user-create.service";

const initialState: HubspotUserCreateInitialStateType = {
  data: {} as HubspotUserCreateResponse,
  loading: false,
  error: undefined,
};

export const postHubspotUserCreate = createAsyncThunk("hubspotUserCreate/post", async (request: HubspotUserCreateType) => {
  const service = new HubspotUserCreateService();

  const response = await service.postUserCreate(request);
  return response.data;
});

export const cleanHubspotUserCreate = createAsyncThunk("hubspotUserCreate/clean", async () => {
  return;
});

const hubspotUserCreateSlice = createSlice({
  name: "hubspotUserCreate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postHubspotUserCreate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postHubspotUserCreate.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as HubspotUserCreateResponse;
        }
      })
      .addCase(postHubspotUserCreate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanHubspotUserCreate.fulfilled, (state) => {
        state.data = {} as HubspotUserCreateResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default hubspotUserCreateSlice.reducer;
