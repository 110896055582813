import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from '../../../errors/api-error-handler';
import { EventDetailsInitialStateType, EventDetailsType } from '../../../models/events/event-details-modal';
import EventDetailsService from '../../../services/events/event-details.service';

const initialState: EventDetailsInitialStateType = {
  EventDetailsData: [],
  EventDetailsLoading: true,
  EventDetailsError: undefined
};


export const retrieveEventDetails = createAsyncThunk('eventDetails/retrieve',
  async (path: string, { rejectWithValue }) => {
    try {

      const response = await EventDetailsService.getEventDetails(path)
      return [response.data]

    } catch (error: any) {
      if (!error.response) {
        throw error
      }
      return rejectWithValue(error.response.status)
    }
  }
)

const eventDetailsSlice = createSlice({
  name: "eventDetails",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveEventDetails.pending, (state) => {
        state.EventDetailsLoading = true;
      })
      .addCase(retrieveEventDetails.fulfilled, (state, action) => {
        if (action.payload) {
          state.EventDetailsLoading = false;
          state.EventDetailsData = action.payload as EventDetailsType[];
        }
      })
      .addCase(retrieveEventDetails.rejected, (state, { payload }) => {
        state.EventDetailsLoading = false;
        state.EventDetailsError = apiErrorHandler(Number(payload));
      });
  },
});

export const selectAllEntities = (state: { entities: any; }) => state.entities;
export default eventDetailsSlice.reducer;
