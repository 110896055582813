import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { HubspotUserInfoInitialStateType, HubspotUserInfoResponse } from "../../../models/hubspot/hubspot-user-info.model";
import HubspotUserInfoService from "../../../services/hubspot/hubspot-user-info.service";

const initialState: HubspotUserInfoInitialStateType = {
  data: {} as HubspotUserInfoResponse,
  loading: true,
  error: undefined,
};

export const retrieveHubspotUserInfo = createAsyncThunk("hubspotUserInfo/retrieve", async (email: string) => {
  const service = new HubspotUserInfoService();

  const response = await service.getUserByEmail(email);
  return response.data;
});

export const cleanHubspotUserInfo = createAsyncThunk("hubspotUserInfo/clean", async () => {
  return;
});

const hubspotUserInfoSlice = createSlice({
  name: "hubspotUserInfo",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHubspotUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveHubspotUserInfo.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as HubspotUserInfoResponse;
        }
      })
      .addCase(retrieveHubspotUserInfo.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanHubspotUserInfo.fulfilled, (state) => {
        state.data = {} as HubspotUserInfoResponse;
        state.loading = true;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default hubspotUserInfoSlice.reducer;
