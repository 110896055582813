import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { HubspotSubscriptionsUpdateInitialStateType, HubspotSubscriptionsUpdateResponse } from "../../../models/hubspot/hubspot-subscriptions-update.model";
import HubspotSubscriptionsUpdateService from "../../../services/hubspot/hubspot-subscriptions-update.service";

interface SubscriptionUpdate {
  userId: string;
  subscriptionField: SubscriptionField[];
}

interface SubscriptionField {
  subscriptionId: number;
  statusState: string;
}

const initialState: HubspotSubscriptionsUpdateInitialStateType = {
  data: {} as HubspotSubscriptionsUpdateResponse,
  loading: false,
  error: undefined,
};

export const postHubspotSubscriptionsUpdate = createAsyncThunk("hubspotSubscriptionsUpdate/post", async (request: SubscriptionUpdate) => {
  const service = new HubspotSubscriptionsUpdateService();

  const response = await service.postPreferenceSubscriptions(request.userId, request.subscriptionField);
  return response.data;
});

export const cleanHubspotSubscriptionsUpdate = createAsyncThunk("hubspotSubscriptionsUpdate/clean", async () => {
  return;
});

const hubspotSubscriptionsUpdateSlice = createSlice({
  name: "hubspotSubscriptionsUpdate",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postHubspotSubscriptionsUpdate.pending, (state) => {
        state.loading = true;
      })
      .addCase(postHubspotSubscriptionsUpdate.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as HubspotSubscriptionsUpdateResponse;
        }
      })
      .addCase(postHubspotSubscriptionsUpdate.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      }).addCase(cleanHubspotSubscriptionsUpdate.fulfilled, (state) => {
        state.data = {} as HubspotSubscriptionsUpdateResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default hubspotSubscriptionsUpdateSlice.reducer;
