import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiErrorHandler } from "../../../errors/api-error-handler";
import { HubspotSubscriptionsInitialStateType, HubspotSubscriptionsResponse } from "../../../models/hubspot/hubspot-subscriptions.model";
import HubspotSubscriptionService from "../../../services/hubspot/hubspot-subscriptions.service";

const initialState: HubspotSubscriptionsInitialStateType = {
  data: {} as HubspotSubscriptionsResponse,
  loading: false,
  error: undefined,
};

export const retrieveHubspotSubscriptions = createAsyncThunk("hubspotSubscriptions/retrieve", async (userId: string) => {
  const service = new HubspotSubscriptionService();

  const response = await service.getPreferenceSubscriptions(userId);
  return response.data;
});

export const cleanHubspotSubscriptions = createAsyncThunk("hubspotSubscriptions/clean", async () => {
  return;
});

const hubspotSubscriptionsSlice = createSlice({
  name: "hubspotSubscriptions",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveHubspotSubscriptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(retrieveHubspotSubscriptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.loading = false;
          state.data = action.payload as HubspotSubscriptionsResponse;
        }
      })
      .addCase(retrieveHubspotSubscriptions.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = apiErrorHandler(Number(payload));
      })
      .addCase(cleanHubspotSubscriptions.fulfilled, (state) => {
        state.data = {} as HubspotSubscriptionsResponse;
        state.loading = false;
        state.error = undefined;
      });
  },
});

export const selectAllEntities = (state: { entities: any }) => state.entities;
export default hubspotSubscriptionsSlice.reducer;
