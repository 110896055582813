import React, { useContext, useEffect, useRef, useState } from 'react';
import { MenuCtx } from './menu-context';
import close from '../../assets/images/cancel.svg';
import { PrimaryMenu } from '../../models/primary-menu-model';
import Collapse from 'react-bootstrap/Collapse';
import { Nav } from 'react-bootstrap';

const MegaMenu = (props: { menu: PrimaryMenu[] }) => {

  const MenuCtxV = useContext(MenuCtx);
  const [isActiveMenu, setIsActiveMenu] = MenuCtxV.menu
  const [dropdown, setDropdown] = useState<number | null>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [openMenuItem, setOpenMenuItem] = useState<number | null>(null)
  const siteHeadercustom = useRef<HTMLDivElement>(null);
  const closeMenu = () => {
    if (MenuCtxV) {
      setIsActiveMenu(false);
      setDropdown(null);
      setOpenMenuItem(null)
    }
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize)
    handleWindowResize();
    (width > 1152) ? setDropdown(0) : setDropdown(null);
    return () => window.addEventListener('resize', handleWindowResize)
  }, [width])

  const handleMenuInMobile = (index: number) => {
    console.log(index, openMenuItem)
    if (width < 1152) {
      if (openMenuItem === index) {
        setDropdown(null)
        setOpenMenuItem(null)
      } else {
        setDropdown(index)
        setOpenMenuItem(index)
      }
    }
  }

  return (
    <Collapse in={isActiveMenu}>
      <div id="site-headercustom" ref={siteHeadercustom} className="site-header site-header-paddingCustom" role="banner">
        <div className="clear"></div>
        <div className={`menu-popup`} id="menu-pop">
          <div className="container position-relative">

            <button className="menu-close-button" onClick={closeMenu}>
              <img src={close} className="close-img-land" alt="cancel" />
            </button>
            <div className="clear"></div>
            <div className="pop-up-menu">
              <div id="vertical_menu" className="resp-vtabs">
                <ul className="resp-tabs-list">
                  {
                    props && props.menu[0]?.data[0]?.childrens?.map((menuItem, index) => {
                      return <li key={index} className={
                        `resp-tab-item h2 ${(dropdown === index) ? 'resp-tab-active' : ''}`}
                        onClick={() => setDropdown(index)}>
                        {menuItem.name}
                      </li>
                    })
                  }
                </ul>
                <div className="resp-tabs-container col-sm-12  col-md-12 col-lg-9 row-second">
                  {
                    props && props.menu[0]?.data[0]?.childrens?.map((menuItem, index) => {
                      return <React.Fragment key={index}>
                        <h2 className="resp-accordion" role="tab" aria-controls="tab-item-1" onClick={() => handleMenuInMobile(index)}><span className="resp-arrow"></span>{menuItem.name}</h2>
                        <div className={`submenu resp-tab-content ${dropdown === index ? 'resp-tab-content-active' : ''}`} id={`submenu${index}`}>
                          <div>  <Nav.Link href="/membership"><span>{menuItem.name}</span></Nav.Link></div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="nav-sub-menu">
                                  <ul>
                                    {menuItem?.childrens?.map((item, index) => {
                                      return <div className="submenu" key={index}>
                                        <div><Nav.Link href={item.page} onClick={() => { setIsActiveMenu(false); setDropdown(0); }}><span>{item.name}</span></Nav.Link></div>
                                      </div>
                                    })}
                                  </ul>
                                </div>
                                <div className="nav-sub-menu">
                                  <ul></ul>
                                </div>
                              </div>
                              <div className="col-sm-6 row-third menuCol3-off menu-scroll">
                                {(menuItem.summary) ? (

                                  <ul className='item-list'>
                                    <li className="item">
                                      <figure className="aside"><img src={menuItem.image} className="img-menu-popup" alt='knowledge-center' /></figure>
                                      <h3 className="menu-pop-up-h3" dangerouslySetInnerHTML={{ __html: menuItem.title }}></h3>
                                      <p className="menu-p">
                                        {menuItem.summary}
                                      </p>
                                      <Nav.Link href={menuItem.linkUrl} className="menu-h4">{menuItem.linkText}</Nav.Link>
                                    </li>
                                  </ul>

                                ) : ''
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    })
                  }

                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  )
}

export default MegaMenu;